import {
  Box,
  BoxProps,
  Button,
  Divider,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackIosOutlined';
import bbox from '@turf/bbox';
import { LngLat, LngLatBoundsLike } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import {
  HighlightableFeature,
  IdentifyFeature,
  useMapFeatureState,
  useMapNavigation
} from '@ljagis/react-mapping';

import FeatureDetails, { AnyAttribute } from '../FeatureDetails';

interface State {
  selectedFeature?: IdentifyFeature;
  hoveredFeature?: IdentifyFeature;
}

export interface IdentifyProps extends BoxProps {
  layerNames: string[];
  features: IdentifyFeature[];
  location: LngLat | null;
  onSelection?: (selection: IdentifyFeature | null) => void;
  onClearFeatures?: () => void;
}

const useStyles = makeStyles({
  /** Item in the multiple features list */
  listItem: {
    '& *': {
      // Prevent mouse out and other events from internal Typography components
      pointerEvents: 'none'
    }
  }
});

const Identify: React.FC<IdentifyProps> = ({
  layerNames,
  features,
  location,
  onSelection,
  onClearFeatures,
  p = 2,
  ...props
}) => {
  const classes = useStyles();

  const { highlightFeature } = useMapFeatureState();
  const { fitBounds } = useMapNavigation();

  const [{ selectedFeature, hoveredFeature }, setState] = useState<State>({});

  // Perform highlight on any selected or hovered features
  // There will be no hovered features when a feature is selected
  // Hovering is only available from the list to select
  const featureToHighlight = selectedFeature || hoveredFeature;

  /** Reset selected feature on identified features change */
  useEffect(() => {
    setState((state) => ({
      ...state,
      selectedFeature: features.length === 1 ? features[0] : undefined,
      hoveredFeature: undefined
    }));
  }, [features]);

  useEffect(() => {
    onSelection && onSelection(selectedFeature || null);
  }, [onSelection, selectedFeature]);

  /** Update highlight for feature to highlight */
  useEffect(() => {
    if (!featureToHighlight?.id) {
      return undefined;
    }

    let unhighlight: () => void;

    try {
      // Types are incompatible but we know the id is defined
      // because of the previous check.
      // Type cast as HighlightableFeature
      unhighlight = highlightFeature(
        featureToHighlight as HighlightableFeature
      );
    } catch (_err) {
      // NOOP
    }

    return () => {
      try {
        unhighlight && unhighlight();
      } catch (_err) {
        // NOOP
      }
    };
  }, [featureToHighlight, highlightFeature]);

  if (selectedFeature) {
    return (
      <Box {...props} display="flex" flexDirection="column" overflow="hidden">
        <Box p={p} pb={2} flex="0 0 auto">
          <Toolbar disableGutters style={{ minHeight: 0 }}>
            {features.length > 1 && (
              <Button
                color="primary"
                startIcon={<BackIcon />}
                style={{ marginLeft: -8, marginTop: -6, marginBottom: -6 }}
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    selectedFeature: undefined
                  }))
                }
              >
                Back
              </Button>
            )}
            <Box flex={1} />
            <Button
              color="primary"
              style={{ marginTop: -6, marginBottom: -6 }}
              onClick={() =>
                fitBounds(bbox(selectedFeature.geometry) as LngLatBoundsLike, {
                  maxZoom: 18,
                  padding: 20
                })
              }
            >
              Zoom To
            </Button>
            <Button
              color="primary"
              style={{ marginRight: -8, marginTop: -6, marginBottom: -6 }}
              onClick={onClearFeatures}
            >
              Clear
            </Button>
          </Toolbar>
        </Box>
        <Divider />
        <FeatureDetails
          flex={1}
          p={p}
          pt={2}
          title={[
            selectedFeature.properties.title,
            selectedFeature.properties.subtitle
          ]
            .filter((v) => !!v)
            .join(' - ')}
          subtitle={selectedFeature.properties.layer}
          attributes={selectedFeature.properties.attributes as AnyAttribute[]}
        />
      </Box>
    );
  }

  if (features.length > 0) {
    return (
      <Box {...props} display="flex" flexDirection="column" overflow="hidden">
        <Box p={p} pb={2} flex="0 0 auto">
          <Toolbar disableGutters style={{ minHeight: 0 }}>
            <Box flex={1}>
              <Typography variant="subtitle1">
                {features.length} Features ({location?.lat.toFixed(5)},{' '}
                {location?.lng.toFixed(5)})
              </Typography>
            </Box>
            <Button
              color="primary"
              style={{ marginRight: -8, marginTop: -6, marginBottom: -6 }}
              onClick={onClearFeatures}
            >
              Clear
            </Button>
          </Toolbar>
        </Box>
        <Divider />
        <Box flex={1} overflow="auto">
          <List>
            {features.map((feature, index) => (
              <ListItem
                className={classes.listItem}
                button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    selectedFeature: feature,
                    hoveredFeature: undefined
                  }))
                }
                onMouseOver={() =>
                  setState((state) => ({ ...state, hoveredFeature: feature }))
                }
                onMouseOut={() =>
                  setState((state) => ({ ...state, hoveredFeature: undefined }))
                }
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {[feature.properties.title, feature.properties.subtitle]
                      .filter((v) => !!v)
                      .join(' - ')}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {feature.properties.layer}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={p} {...props}>
      <Typography variant="subtitle1" align="center" paragraph>
        No features are currently selected
      </Typography>
      {layerNames.length > 0 && (
        <React.Fragment>
          <Typography variant="subtitle1" align="center" paragraph>
            Click a feature on the map from the following map layers to see more
            information about the feature.
          </Typography>
          <List dense>
            {layerNames.map((name) => (
              <ListItem key={name} disableGutters>
                <Typography color="secondary">{name}</Typography>
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Identify;
