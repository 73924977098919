import {
  AnyLayer,
  Layer,
  LngLat,
  MapDataEvent,
  MapMouseEvent,
  MapboxGeoJSONFeature,
  PointLike
} from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';

import { METADATA_IDENTIFY } from '../constants';
import { useMapContext } from './MapContext';

const PIXEL_BUFFER = 5;

/** Feature attribute intended for display in the UI */
export interface FeatureAttribute {
  label: string;
  kind: 'text' | 'link' | 'image';
  value?: unknown;
  url?: string;
}

/** Properties for GeoJSON features returned from an identify */
export interface FeatureProperties {
  /** Readable layer name provided by the layer metadata */
  layer: string;
  /** Feature title intended as the primary display when describing the feature */
  title: string;
  /** Feature subtitle intended as a secondary display when describing the feature */
  subtitle?: string;
  attributes: FeatureAttribute[];
}

export interface IdentifyFeature
  extends GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties> {
  source: string;
  sourceLayer: string;
}

interface MapIdentifyResult {
  isActive: boolean;
  /** IDs of layers that may be identified. Actual results in `features`. */
  layerIds: string[];
  /** Names (from metadata) of layers that may be identified. Actual results in `features`. */
  layerNames: string[];
  /** Features (from layers that can be identified, metadata) identified at last map click */
  features: IdentifyFeature[];
  /** Location of last map click used to identify */
  location: LngLat | null;
  activate: () => void;
  deactivate: () => void;
  clearFeatures: () => void;
}

/** Shape of metadata expected for each identifiable layer (`lja:identify`) */
interface IdentifyMetadata {
  'layer-name'?: string;
  'title-field'?: string;
  'subtitle-field'?: string;
  attributes: {
    field?: string;
    kind?: 'text' | 'link' | 'image';
    label?: string;
  }[];
}

interface IdentifyMetadataWithId extends IdentifyMetadata {
  layerId: string;
}

/** Returns identify configuration for each layer with identify related metadata */
const identifyMetadata = (styleLayers: AnyLayer[]): IdentifyMetadataWithId[] =>
  styleLayers.reduce<IdentifyMetadataWithId[]>((acc, layer) => {
    // Have to cast as layer for `metadata` prop.
    // `CustomLayerInterface` in the `AnyLayer` union has no metadata.
    const metadata: IdentifyMetadata = (layer as Layer).metadata?.[
      METADATA_IDENTIFY
    ];

    if (!metadata) return acc;

    return [...acc, { layerId: layer.id, ...metadata }];
  }, []);

/** Identified features with properties formatted as `FeatureProperties` */
const formatIdentifyResults = (
  styleMetadatas: IdentifyMetadataWithId[],
  results: MapboxGeoJSONFeature[]
) =>
  results.reduce<IdentifyFeature[]>((acc, feat) => {
    const featMetadata = styleMetadatas.find(
      (m) => m.layerId === feat.layer.id
    );

    if (!featMetadata) {
      // This case should not happen in typical circumstances.
      // We are passing layerIds from identifyLayers (metadatas) to query,
      // so only those layers should be returned.
      return acc;
    }

    const attributes = (featMetadata.attributes || []).reduce<
      FeatureAttribute[]
    >((acc, attribute) => {
      if (!(attribute.label && attribute.field)) return acc;

      const kind = attribute.kind || 'text';

      return [
        ...acc,
        {
          label: attribute.label,
          kind,
          value:
            kind === 'text' ? feat.properties?.[attribute.field] : undefined,
          url: ['link', 'image'].includes(kind)
            ? feat.properties?.[attribute.field]
            : undefined
        }
      ];
    }, []);

    const titleField = featMetadata['title-field'];
    const subtitleField = featMetadata['subtitle-field'];

    return [
      ...acc,
      {
        id: feat.id,
        type: feat.type,
        geometry: feat.geometry,
        source: feat.source,
        sourceLayer: feat.sourceLayer,
        properties: {
          layer: featMetadata['layer-name'] || '',
          title: (titleField && feat.properties?.[titleField]) || '',
          subtitle: (subtitleField && feat.properties?.[subtitleField]) || '',
          attributes
        }
      }
    ];
  }, []);

export const useMapIdentify = (isActive = true): MapIdentifyResult => {
  const { map: mapInstance } = useMapContext();
  const layerCountRef = useRef(0);

  const [activeState, setActiveState] = useState<boolean>(isActive);

  const [layerState, setLayersState] = useState<{
    metadatas: IdentifyMetadataWithId[];
    ids: string[];
    names: string[];
  }>({ metadatas: [], ids: [], names: [] });

  const [featureState, setFeatureState] = useState<{
    features: IdentifyFeature[];
    location: LngLat | null;
  }>({ features: [], location: null });

  const activate = (): void => {
    setActiveState(true);
  };

  const deactivate = (): void => {
    setActiveState(false);
  };

  /** Parse data for identifiable layers on style loads */
  useEffect(() => {
    if (!mapInstance) {
      setLayersState({ metadatas: [], ids: [], names: [] });
      return undefined;
    }

    const parseIdentifyLayers = (styleLayers: AnyLayer[] | undefined) => {
      if (!styleLayers) {
        setLayersState({ metadatas: [], ids: [], names: [] });
        return;
      }

      const metadatas = identifyMetadata(styleLayers);

      const [ids, names] = metadatas.reduce<[string[], string[]]>(
        (acc, metadata) => [
          [...acc[0], metadata.layerId],
          [...acc[1], metadata['layer-name'] || '']
        ],
        [[], []]
      );

      setLayersState({ metadatas, ids, names });
      layerCountRef.current = styleLayers.length;
    };

    const onStyleData = ({ target, dataType }: MapDataEvent) => {
      if (dataType === 'source') {
        return;
      }

      const styleLayers = target.getStyle()?.layers || [];

      if (layerCountRef.current === styleLayers.length) {
        return;
      }

      parseIdentifyLayers(styleLayers);
    };

    mapInstance.on('styledata', onStyleData);
    parseIdentifyLayers(mapInstance.getStyle()?.layers);

    return () => {
      mapInstance.off('styledata', onStyleData);
    };
  }, [mapInstance]);

  /** Perform identify on map clicks, update map cursor when moving over identifiable features */
  useEffect(() => {
    if (!(mapInstance && layerState.ids.length && activeState)) {
      setFeatureState({ features: [], location: null });
      return undefined;
    }

    const onMapClick = ({ lngLat }: MapMouseEvent) => {
      const screenPoint = mapInstance.project(lngLat);

      const bbox: [PointLike, PointLike] = [
        [screenPoint.x - PIXEL_BUFFER, screenPoint.y - PIXEL_BUFFER],
        [screenPoint.x + PIXEL_BUFFER, screenPoint.y + PIXEL_BUFFER]
      ];

      const queryFeatureResults = mapInstance.queryRenderedFeatures(bbox, {
        layers: layerState.ids
      });

      // Ensure queryRenderedFeatures doesn't return duplicate features
      const uniqueFeatureIdDict = {};
      const uniqueQueryResults = queryFeatureResults.reduce((acc, feature) => {
        if (!feature.id) {
          return [...acc, feature];
        }

        const uniqueFeatureId = `${feature.id}-${feature.layer.id}`;
        if (uniqueFeatureIdDict[uniqueFeatureId]) {
          return acc;
        }

        uniqueFeatureIdDict[uniqueFeatureId] = true;
        return [...acc, feature];
      }, [] as MapboxGeoJSONFeature[]);

      const formattedFeatures = formatIdentifyResults(
        layerState.metadatas,
        uniqueQueryResults
      );

      setFeatureState({
        features: formattedFeatures,
        location: lngLat
      });
    };

    mapInstance.on('click', onMapClick);

    const onMouseEnter = () => {
      mapInstance.getCanvas().style.cursor = 'pointer';
    };

    const onMouseLeave = () => {
      mapInstance.getCanvas().style.cursor = '';
    };

    // Change mouse cursor to a pointer for identifiable layers
    layerState.ids.forEach((layerId) => {
      mapInstance.on('mouseenter', layerId, onMouseEnter);
      mapInstance.on('mouseleave', layerId, onMouseLeave);
    });

    return () => {
      mapInstance.off('click', onMapClick);

      layerState.ids.forEach((layerId) => {
        mapInstance.off('mouseenter', layerId, onMouseEnter);
        mapInstance.off('mouseleave', layerId, onMouseLeave);
      });
    };
  }, [mapInstance, layerState, activeState]);

  return {
    isActive: activeState,
    layerIds: layerState.ids,
    layerNames: layerState.names,
    features: featureState.features,
    location: featureState.location,
    activate,
    deactivate,
    clearFeatures: () => {
      setFeatureState({ features: [], location: null });
    }
  };
};
