import { SvgIconProps } from '@material-ui/core';
import ZoomOutIcon from '@material-ui/icons/Remove';
import * as React from 'react';
import { useMapNavigation } from '@ljagis/react-mapping';

import MapButton from '../MapButton';

type ZoomOutButtonProps = Pick<SvgIconProps, 'color'>;

const ZoomOutButton: React.FC<ZoomOutButtonProps> = ({ color = 'action' }) => {
  const { zoomOut } = useMapNavigation();
  return (
    <MapButton onClick={zoomOut}>
      <ZoomOutIcon color={color} style={{ fontSize: 20 }} />
    </MapButton>
  );
};

export default ZoomOutButton;
