import mapboxgl, {
  LngLat,
  LngLatBounds,
  Map as MapboxGL,
  MapboxEvent,
  MapboxOptions,
  MapMouseEvent
} from 'mapbox-gl';
import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { useMapboxContext } from '../../MapboxContext';
import { useMapContext, useSetMapContext } from '../../MapContext';

import useMapboxStyles from './useMapboxStyles';
import useStyles from './useStyles';

// eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires, import/no-unresolved, @typescript-eslint/no-explicit-any
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export interface Viewport {
  center: LngLat;
  zoom: number;
  bearing: number;
  pitch: number;
  bounds: LngLatBounds;
}

export interface MapProps extends React.HTMLProps<HTMLDivElement> {
  minZoom?: number;
  maxZoom?: number;
  /**
   * Optional initial map style.
   * Use for convenience when applying a default style
   * or when the map will only ever have a single style.
   * For most interactions with map style, including switching,
   * you will want to use `MapContext/useMapStyle`.
   */
  initialMapStyle?: string;
  initialCenter?: LngLat;
  initialZoom?: number;
  /** [west, south, east, north] */
  initialBounds?: [number, number, number, number];
  /** Pixels */
  initialBoundsPadding?: number;
  onMapClick?: (location: LngLat) => void;
  /**
   * Called while a map is navigating (pan, zoom, flyTo, fitBounds, etc..)
   * @param viewport - Map viewport after the navigation
   */
  onNavigating?: (viewport: Viewport) => void;
}

const viewportFromMap = (map: MapboxGL) => {
  const center = map.getCenter();
  const zoom = map.getZoom();
  const bearing = map.getBearing();
  const pitch = map.getPitch();
  const bounds = map.getBounds();
  return { center, zoom, bearing, pitch, bounds };
};

const Map: React.FC<MapProps> = ({
  minZoom,
  maxZoom,
  initialMapStyle,
  initialCenter,
  initialZoom,
  initialBounds,
  initialBoundsPadding,
  onMapClick,
  onNavigating,
  ...divProps
}) => {
  useMapboxStyles();
  useStyles();

  // Detect component resize to also resize map control
  const { width, height, ref } = useResizeDetector({ skipOnMount: true });
  const rootRef = ref as MutableRefObject<HTMLDivElement> | undefined;

  const { accessToken } = useMapboxContext();

  // Load into ref to only use initial value and not be notified on props changes
  const mapOptions = useRef<Partial<MapboxOptions>>({
    style: initialMapStyle,
    minZoom,
    maxZoom,
    center: initialCenter,
    bounds: initialBounds,
    fitBoundsOptions: { padding: initialBoundsPadding }
  });

  if (initialZoom) mapOptions.current.zoom = initialZoom;

  const setMapInstance = useSetMapContext();
  const { map: mapInstance } = useMapContext();

  useLayoutEffect(() => {
    if (!rootRef?.current) {
      throw new Error('`rootRef` not defined');
    }

    const map = new MapboxGL({
      container: rootRef.current,
      accessToken,
      ...mapOptions.current
    });

    map.on('load', () => {
      setMapInstance(map);
    });

    return () => {
      map.remove();
      setMapInstance(undefined);
    };
  }, [rootRef, accessToken, setMapInstance]);

  // Resize Mapbox `Map` on container size changes
  // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#resize
  useEffect(() => {
    if (!(mapInstance && height && width)) return;
    mapInstance.resize();
  }, [height, width, mapInstance]);

  // Call `onMapClick` on map click events
  useEffect(() => {
    if (!(mapInstance && onMapClick)) return undefined;

    const onClick = ({ lngLat }: MapMouseEvent) => {
      onMapClick(lngLat);
    };

    mapInstance.on('click', onClick);

    return () => {
      mapInstance.off('click', onClick);
    };
  }, [mapInstance, onMapClick]);

  // Call `onNavigating` on map move events
  useEffect(() => {
    if (!(mapInstance && onNavigating)) return undefined;

    const publishNavigating = (map: MapboxGL) => {
      onNavigating(viewportFromMap(map));
    };

    const onMove = ({ target: map }: MapboxEvent<unknown>) => {
      publishNavigating(map);
    };

    mapInstance.on('move', onMove);
    publishNavigating(mapInstance);

    return () => {
      mapInstance.off('move', onMove);
    };
  }, [mapInstance, onNavigating]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div ref={rootRef} {...divProps} />;
};

export default Map;
