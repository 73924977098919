import { useEffect, useState } from 'react';
import { Layer } from 'mapbox-gl';
import {
  METADATA_LEGENDS,
  METADATA_LEGEND,
  useMapStyle
} from '@ljagis/react-mapping';

export enum LegendKind {
  SmallImage = 'SmallImage',
  LargeImage = 'LargeImage'
}

interface SmallImage {
  name: string;
  kind: LegendKind.SmallImage;
  img: string;
  layerIds: string[];
  visible: boolean;
}

interface LargeImage {
  name: string;
  kind: LegendKind.LargeImage;
  img: string;
  layerIds: string[];
  visible: boolean;
}

type LegendRecord = Record<string, SmallImage | LargeImage>;

type AnyLegend = SmallImage | LargeImage;

type AnyLegendArray = AnyLegend[];

interface LegendResult {
  loading: boolean;
  legends: AnyLegendArray;
}

const initialState: LegendResult = {
  loading: true,
  legends: []
};

export const useLegend = (): LegendResult => {
  const { mapStyle } = useMapStyle();

  const [resultState, setResultState] = useState<LegendResult>(initialState);

  useEffect(() => {
    const legendsMetadata = mapStyle?.metadata?.[METADATA_LEGENDS];
    const styleLayers = mapStyle?.layers;

    if (!styleLayers) {
      return;
    }

    if (!legendsMetadata) {
      // If `styleLayers` has a value, style has loaded.
      // If style has loaded and no `legendsMetadata`, metadata is not defined in style.
      // Reset to initial state

      setResultState({ ...initialState, loading: false });
      return;
    }

    const result: LegendRecord = styleLayers.reduce((acc, layer: Layer) => {
      const legendId = layer?.metadata?.[METADATA_LEGEND];
      if (!(legendId && legendsMetadata[legendId])) {
        // No legend metadata defined. Do not include in component data.
        return acc;
      }
      const isLayerVisible = layer?.layout?.visibility !== 'none';

      const prev = acc[legendId] || {
        layerIds: [],
        layerVisibility: false
      };

      return {
        ...acc,
        [legendId]: {
          ...legendsMetadata[legendId],
          layerIds: [...prev.layerIds, layer.id],
          visible: prev.layerVisibility || isLayerVisible
        }
      };
    }, {});

    const legends = Object.values(result).filter((layer) => layer.visible);

    setResultState({ loading: false, legends });
  }, [mapStyle]);

  return resultState;
};
