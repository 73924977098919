import Fuse from 'fuse.js';
import isEqual from 'lodash.isequal';
import { useEffect, useRef, useState } from 'react';

interface FuzzySearchResult<T> {
  results: T[];
  query: string | undefined;
  setQuery: (query: string | undefined) => void;
}

export interface FuzzySearchOptions<T> {
  list: T[];
  options?: Fuse.IFuseOptions<T>;
}

export const useFuzzySearch = <T>({
  list,
  options
}: FuzzySearchOptions<T>): FuzzySearchResult<T> => {
  const [query, setQuery] = useState<string>();
  const [results, setResults] = useState<T[]>(list);

  const fuseRef = useRef<Fuse<T>>();

  useEffect(() => {
    fuseRef.current = new Fuse(list, options);
  }, [list, options]);

  useEffect(() => {
    if (!(fuseRef && fuseRef.current)) {
      return;
    }

    const newResults = query
      ? fuseRef.current.search(query).map(({ item }) => item)
      : list;

    setResults((prev) => (isEqual(prev, newResults) ? prev : newResults));
  }, [list, query]);

  return {
    results,
    query,
    setQuery
  };
};
