import { Box, InputAdornment, InputBase, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { LngLatLike } from 'mapbox-gl';
import React, { useState } from 'react';
import {
  useGeoJSONLayer,
  useMapNavigation,
  emptyGeoJSONFeature
} from '@ljagis/react-mapping';

import layers from './layers';

export interface LocationSearchResult {
  primaryText: string;
  secondaryText?: string;
  geometry: GeoJSON.Geometry;
}

interface LocationSearchProps {
  /**
   * Maximum zoom when flying to results.
   * For point geometries, map flies to `maxZoom`.
   * For other geometries, map flies to bounding box but no closer than `maxZoom`.
   */
  maxZoom?: number;
  /** Search input placeholder */
  placeholder?: string;
  /** Function to perform search and return a `LocationSearchResult` array */
  onSearch: (text: string) => Promise<LocationSearchResult[]>;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  maxZoom = 14,
  placeholder = 'Search for an address',
  onSearch
}) => {
  const { flyTo } = useMapNavigation();
  const {
    setData: setGeoJSONData,
    clearData: clearGeoJSONData
  } = useGeoJSONLayer({ layers });

  const [selected, setSelected] = useState<LocationSearchResult | null>(null);
  const [history, setHistory] = useState<LocationSearchResult[]>([]);
  const [results, setResults] = useState<LocationSearchResult[]>([]);

  const handleSelect = (selected: LocationSearchResult) => {
    if (selected.geometry.type === 'Point') {
      setGeoJSONData({ ...emptyGeoJSONFeature, geometry: selected.geometry });
      flyTo(selected.geometry.coordinates as LngLatLike, maxZoom);
    }

    // TODO. Support geometries other than points

    // Remove any previous records of current selection from history.
    // A new item will be added to the top of the history.
    // String match concatenated primary and secondary text.
    const selectedRemoved = history.filter(
      (historyItem) =>
        `${historyItem.primaryText}${historyItem.secondaryText}` !==
        `${selected.primaryText}${selected.secondaryText}`
    );

    // Limit history to 10 items
    const newHistory = [selected, ...selectedRemoved].slice(0, 10);

    setHistory(newHistory);
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={({ primaryText }) => primaryText}
      options={results.length ? results : history}
      value={selected}
      noOptionsText="No results"
      clearOnBlur={false}
      onChange={(_, value: LocationSearchResult | null) => {
        value && handleSelect(value);
      }}
      onInputChange={async (_, value, reason) => {
        if (reason === 'reset' || !value.length) {
          setSelected(null);
          setResults([]);
          clearGeoJSONData();
          return;
        }

        if (reason === 'input') {
          // Only updated on user input
          // Input also changes when a selection is made. That will not set the input value.
          clearGeoJSONData();
          try {
            const results = await onSearch(value);
            setResults(results);
          } catch (_err) {
            // TODO. Error while searching. Show snackbar to inform the user.
          }
        }
      }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <Box py={0.5} px={1}>
            <InputBase
              placeholder={placeholder}
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              }
              style={{ display: 'flex' }}
              {...params.inputProps}
            />
          </Box>
        </div>
      )}
      renderOption={(result) => {
        return (
          <Box
            minHeight={32}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="body1">{result.primaryText}</Typography>
            {result.secondaryText !== undefined && (
              <Typography variant="body2" color="textSecondary">
                {result.secondaryText}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

export default LocationSearch;
