import { CircleLayer, FillLayer, LineLayer } from 'mapbox-gl';

export const layerOpacity = 0.7;
export const layerColor = '#18ffff';

type LayerOmit = 'id';

const layers: [
  Omit<CircleLayer, LayerOmit>,
  Omit<LineLayer, LayerOmit>,
  Omit<FillLayer, LayerOmit>
] = [
  {
    type: 'circle' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], ['', 'Point'], true, false],
      ['match', ['geometry-type'], ['', 'MultiPoint'], true, false]
    ],
    paint: {
      'circle-opacity': 0,
      'circle-stroke-opacity': layerOpacity,
      'circle-stroke-color': layerColor,
      'circle-radius': 8,
      'circle-stroke-width': 4
    }
  },
  {
    type: 'line' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], ['', 'LineString'], true, false],
      ['match', ['geometry-type'], ['', 'MultiLineString'], true, false]
    ],
    paint: {
      'line-opacity': layerOpacity,
      'line-color': layerColor,
      'line-width': 6
    },
    layout: {
      'line-join': 'round' as const,
      'line-cap': 'round' as const
    }
  },
  {
    type: 'fill' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], ['', 'Polygon'], true, false],
      ['match', ['geometry-type'], ['', 'MultiPolygon'], true, false]
    ],
    paint: {
      'fill-opacity': layerOpacity,
      'fill-color': layerColor
    }
  }
];

export default layers;
