import {
  Box,
  BoxProps,
  Collapse,
  List,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import NoLayersIcon from '@material-ui/icons/LayersClearOutlined';
import React, { useState } from 'react';

import GroupListItem from './GroupListItem';
import HideAllListItem from './HideAllListItem';
import LoadingListItem from './LoadingListItem';
import ShowAllListItem from './ShowAllListItem';
import SingularListItem from './SingleListItem';
import {
  AnyLayer,
  GroupedLayer,
  LayerKind,
  useLayerVisibility
} from './useLayerVisibility';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    nested: {
      paddingLeft: theme.spacing(3)
    }
  })
);

type RootSizing = Pick<BoxProps, 'flex' | 'height' | 'width'>;

interface LayerVisibilityProps extends RootSizing {
  toggleAllButton?: boolean;
}

const RecursiveListMapper: React.FC<{ layerGroup: AnyLayer }> = ({
  layerGroup
}) => {
  if (layerGroup.kind === LayerKind.Single) {
    const {
      name,
      visible,
      onToggleVisibility,
      onZoomIn,
      onZoomOut
    } = layerGroup;
    return (
      <SingularListItem
        layerName={name}
        showLayer={visible}
        toggleShowLayer={onToggleVisibility}
        zoomIn={onZoomIn}
        zoomOut={onZoomOut}
      />
    );
  }

  if (layerGroup.kind === LayerKind.Group) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <GroupedItem groupedLayer={layerGroup} />;
  }

  return null;
};

const GroupedItem: React.FC<{ groupedLayer: GroupedLayer }> = ({
  groupedLayer
}) => {
  const { name, visible, onToggleVisibility, groups } = groupedLayer;

  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <React.Fragment>
      <GroupListItem
        groupName={name}
        showLayers={visible}
        isExpanded={isExpanded}
        toggleShowLayers={onToggleVisibility}
        toggleExpand={() => setIsExpanded(!isExpanded)}
      />
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List className={classes.nested} component="div" disablePadding dense>
          {groups.map((layerGroup) => (
            <RecursiveListMapper
              key={layerGroup.name}
              layerGroup={layerGroup}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const LayerVisibility: React.FC<LayerVisibilityProps> = ({
  toggleAllButton = true,
  ...rootProps
}) => {
  const classes = useStyles();

  const {
    hasVisible,
    layerGroups,
    loading,
    toggleAllLayers
  } = useLayerVisibility();

  if (loading) {
    return <LoadingListItem />;
  }

  if (layerGroups.length === 0) {
    // Items have loaded but no visibility layers are configured
    return (
      <Box
        p={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        {...rootProps}
      >
        <Typography
          style={{ lineHeight: 1, marginRight: 16 }}
          component="span"
          color="textSecondary"
        >
          <NoLayersIcon />
        </Typography>
        <Typography component="span" color="textSecondary">
          No Layers Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" minHeight={0} {...rootProps}>
      {toggleAllButton && (
        <Box mt={1} flex="0 0 auto">
          {hasVisible ? (
            <HideAllListItem onClick={toggleAllLayers} />
          ) : (
            <ShowAllListItem onClick={toggleAllLayers} />
          )}
        </Box>
      )}
      <Box flex={1} minHeight={0} overflow="hidden auto">
        <List className={classes.root} dense>
          {layerGroups.map((layerGroup) => (
            <RecursiveListMapper
              key={layerGroup.name}
              layerGroup={layerGroup}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default LayerVisibility;
