import { useEffect, useState } from 'react';
import { Layer } from 'mapbox-gl';

import {
  METADATA_VISIBILITIES,
  METADATA_VISIBILITY,
  useMapStyle,
  useMapLayerVisibility,
  useMapNavigation,
  useMapZoomInteger
} from '@ljagis/react-mapping';

/** Default `sort` given to visibilities metadata when not defined */
const defaultSort = 999;

/** @returns function to toggle based on current visibility */
const toggleLayers = (
  visible: boolean,
  layerIds: string[],
  hideLayers: (lIds: string[]) => void,
  showLayers: (lIds: string[]) => void
) => {
  return visible
    ? hideLayers.bind(null, layerIds)
    : showLayers.bind(null, layerIds);
};

export enum LayerKind {
  Single = 'Single',
  Group = 'Group'
}

interface LayerDefinition {
  kind: LayerKind;
  layerIds: string[];
  minZoom: number;
  maxZoom: number;
  name: string;
  sort: number;
  visible: boolean;
  onToggleVisibility: () => void;
}

export interface SingleLayer extends LayerDefinition {
  kind: LayerKind.Single;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

export interface GroupedLayer extends LayerDefinition {
  kind: LayerKind.Group;
  groups: SingleLayer[];
}

type LayerRecord = Record<string, SingleLayer | GroupedLayer>;

export type AnyLayer = SingleLayer | GroupedLayer;

type AnyLayerArray = AnyLayer[];

interface LayerVisibilityConfig {
  debug: boolean;
}

interface LayerVisibilityResult {
  loading: boolean;
  hasVisible: boolean;
  layerGroups: AnyLayerArray;
  toggleAllLayers: () => void;
}

const logDebugging = (...messages: unknown[]) => {
  // eslint-disable-next-line no-console
  console.debug('[useLayerVisibility]', ...messages);
};

const initialState: LayerVisibilityResult & { lastComparedLevel?: number } = {
  loading: true,
  layerGroups: [],
  hasVisible: false,
  toggleAllLayers: () => {
    return undefined;
  },
  lastComparedLevel: undefined
};

export const useLayerVisibility = (
  config: LayerVisibilityConfig = { debug: false }
): LayerVisibilityResult => {
  const { debug } = config;

  const { mapStyle } = useMapStyle();

  const [resultState, setResultState] = useState<
    LayerVisibilityResult & { lastComparedLevel?: number }
  >(initialState);

  const { showLayers, hideLayers } = useMapLayerVisibility();
  const { levelCompare } = useMapZoomInteger();
  const { zoomTo } = useMapNavigation();

  useEffect(() => {
    debug && logDebugging('MAP STYLE CHANGE EFFECT');

    const visibilityMetadata = mapStyle?.metadata?.[METADATA_VISIBILITIES];
    const styleLayers = mapStyle?.layers;

    if (!styleLayers) {
      debug &&
        logDebugging(
          'EXITING',
          'Missing style layers. Style is likely not loaded'
        );
      return;
    }

    if (!visibilityMetadata) {
      // If `styleLayers` has a value, style has loaded.
      // If style has loaded and no `visibilityMetadata`, metadata is not defined in style.
      // Reset to initial state
      debug &&
        logDebugging(
          'SETTING STATE',
          'No visibility metadata. Resetting to default state except loading false'
        );
      setResultState({ ...initialState, loading: false });
      return;
    }

    // Set fallback default for undefined sort props
    Object.keys(visibilityMetadata).forEach((key) => {
      visibilityMetadata[key].sort =
        visibilityMetadata[key].sort || defaultSort;
    });

    /**
     * Dict of all layer visibilities (root metadata) by ID
     * with aggregated data from all participating layers.
     * Only layer visibilities with participating layers included.
     */
    const layerDefinitions: Record<
      string,
      LayerDefinition
    > = styleLayers.reduce((acc, layer: Layer) => {
      const visibilityId = layer?.metadata?.[METADATA_VISIBILITY];

      if (!(visibilityId && visibilityMetadata[visibilityId])) {
        // No visibility metadata defined. Do not include in component data.
        return acc;
      }

      const layerMinZoom = layer.minzoom !== undefined ? layer.minzoom : 0;
      const layerMaxZoom = layer.maxzoom !== undefined ? layer.maxzoom : 22;
      const isLayerVisible = layer.layout?.visibility !== 'none';

      const prev = acc[visibilityId] || {
        layerIds: [],
        layerVisibility: false,
        minZoom: undefined,
        maxZoom: undefined
      };

      return {
        ...acc,
        [visibilityId]: {
          ...visibilityMetadata[visibilityId],
          layerIds: [...prev.layerIds, layer.id],
          minZoom:
            prev.minZoom !== undefined && prev.minZoom < layerMinZoom
              ? prev.minZoom
              : layerMinZoom,
          maxZoom:
            prev.maxZoom !== undefined && prev.maxZoom > layerMaxZoom
              ? prev.maxZoom
              : layerMaxZoom,
          visible: prev.layerVisibility || isLayerVisible
        }
      };
    }, {});

    // Aggregate of all layers for showing/hiding all
    let allLayerIds: string[] = [];
    let anyVisible = false;

    /**
     * Group by splitting names with "|".
     * Currently only handles 1 level of nesting (A LayerKind.Group can't have any LayerKind.Group)
     * ex. 2 layer definitions of DD6 Easements|Drainage Easements and DD6 Easements|Encroachments
     * would both be in the DD6 Easements group,
     */
    const grouped: LayerRecord = Object.values(layerDefinitions).reduce(
      (acc, visibility) => {
        const { layerIds, visible, sort } = visibility;

        allLayerIds = [...allLayerIds, ...layerIds];
        anyVisible = anyVisible || visible;

        const SPLIT_CHAR = '|';

        const parts = visibility.name.split(SPLIT_CHAR);

        if (parts.length > 1) {
          // Part of group based on split char (ie. 'Group|Layer')
          const group = parts[0];

          const prev = acc[group] || {
            visible: false,
            layerIds: [],
            groups: [],
            sort: defaultSort
          };

          return {
            ...acc,
            [group]: {
              kind: LayerKind.Group,
              name: group,
              sort: sort < prev.sort ? sort : prev.sort,
              visible: prev.visible || visible,
              layerIds: [...prev.layerIds, ...layerIds],
              groups: [
                ...prev.groups,
                {
                  ...visibility,
                  kind: LayerKind.Single,
                  // Only first split used for grouping
                  // Join back any others (ie. Group|Layer|StillLayer -> Group, Layer|StillLayer)
                  name: parts.slice(1).join(SPLIT_CHAR)
                }
              ]
            }
          };
        }

        // Single split part. Not part of group, single layer.
        return {
          ...acc,
          [visibility.name]: { ...visibility, kind: LayerKind.Single }
        };
      },
      {}
    );

    /** Recursively add toggle functions to all groups and layers based on visibility */
    const mapToggleVisibilityToLayerGroups = (
      layerGroup: AnyLayer
    ): AnyLayer => {
      const onToggleVisibility = toggleLayers(
        layerGroup.visible,
        layerGroup.layerIds,
        hideLayers,
        showLayers
      );

      if (layerGroup.kind === LayerKind.Single) {
        return {
          ...layerGroup,
          onToggleVisibility
        } as SingleLayer;
      }

      if (layerGroup.kind === LayerKind.Group) {
        return {
          ...layerGroup,
          onToggleVisibility,
          groups: layerGroup.groups
            .map((innerLayerGroup: AnyLayer) =>
              mapToggleVisibilityToLayerGroups(innerLayerGroup)
            )
            .sort((a, b) => a.sort - b.sort)
        } as GroupedLayer;
      }

      // NOTE. All cases should be handled and this should not be reached
      return layerGroup;
    };

    /** Final groupings with toggle visibility functions attached */
    const layerGroups = Object.values(grouped).map((layerGroup) => {
      return mapToggleVisibilityToLayerGroups(layerGroup);
    });

    const sortedLayerGroups = layerGroups.sort((a, b) => a.sort - b.sort);

    const state = {
      loading: false,
      layerGroups: sortedLayerGroups,
      hasVisible: anyVisible,
      toggleAllLayers: toggleLayers(
        anyVisible,
        allLayerIds,
        hideLayers,
        showLayers
      )
    };

    debug && logDebugging('SETTING STATE', 'Style', state);

    setResultState(state);
  }, [debug, mapStyle, showLayers, hideLayers]);

  useEffect(() => {
    debug && logDebugging('LEVEL COMPARE CHANGE EFFECT');

    if (
      levelCompare === undefined ||
      levelCompare === resultState.lastComparedLevel
    ) {
      debug &&
        logDebugging(
          'EXITING',
          'No change in `levelCompare`',
          levelCompare,
          resultState.lastComparedLevel
        );
      return;
    }

    /** Recursive map over groups to add zoom functions */
    const mapZoomFunctions = (layerGroup: AnyLayer): AnyLayer => {
      if (layerGroup.kind === LayerKind.Group) {
        return {
          ...layerGroup,
          groups: layerGroup.groups.map((innerLayerGroup) => {
            return mapZoomFunctions(innerLayerGroup);
          })
        } as GroupedLayer;
      }

      if (layerGroup.minZoom > levelCompare) {
        return {
          ...layerGroup,
          onZoomIn: zoomTo.bind(null, layerGroup.minZoom)
        };
      }

      if (levelCompare >= layerGroup.maxZoom) {
        return {
          ...layerGroup,
          // Layer is visible when < maxzoom, not visible at the max
          // Subtract 0.1 to make visible
          onZoomOut: zoomTo.bind(null, layerGroup.maxZoom - 0.1)
        };
      }

      return { ...layerGroup, onZoomIn: undefined, onZoomOut: undefined };
    };

    /**
     * Mapping through all LayerKind.Single to add function to zoom in/out
     * of map to be able to see a layer based on current map zoom.
     * Because map zoom level will not affect the mapStyle, this is in it's own useEffect to prevent
     * rebuilding the layer groups again.
     */
    const watchingZoom = resultState.layerGroups.map((layerGroup) => {
      return mapZoomFunctions(layerGroup);
    });

    debug &&
      logDebugging(
        'SETTING STATE',
        'Level Compare',
        levelCompare,
        watchingZoom
      );

    setResultState((state) => ({
      ...state,
      layerGroups: watchingZoom,
      lastComparedLevel: levelCompare
    }));
  }, [debug, levelCompare, resultState, zoomTo]);

  return resultState;
};
